import Model from './_base';

export default class FileUpload extends Model {
    static entity = 'upload';

    constructor(data) {
        super(FileUpload.entity);
        if(data){
            Object.assign(this, data);
            return;
        }
    }

    static save(companyCode, data, type){
        const formData = new FormData()
        formData.append('file', data)
        let config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return Model.axios.post(this.url('/' + companyCode + '/' + type), formData, config);
    }

    static download(companyCode, code){
        return new Promise((resolve, reject) => {
            Model.axios({
                url: this.url('/' + companyCode + '/' + code),
                method: 'GET',
                responseType: 'blob'
            }).then(result => {
                const blob = new Blob([result.data], {type: "octet/stream"});
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = result.headers['content-disposition'].split("filename=")[1];
                link.click();
                URL.revokeObjectURL(link.href);
                resolve();
            }).catch(reason => reject(reason));
        });
    }
}