<template>
  <div>
    <ul class="biz-breadcrumb" v-if="!isRegistrationWizard">
      <li><router-link to="/supplier/manage">{{$t('SYSTEM_MANAGEMENT.TITLE')}}</router-link></li>
      <li><a>{{$t('PR_INFO.TITLE')}}</a></li>
    </ul>
    <div class="page">
      <div class="page-title" v-if="!isRegistrationWizard">
        <h1>{{$t('PR_INFO.TITLE')}}</h1>
        <p>{{$t('PR_INFO.DESCRIPTION')}}</p>
      </div>
      <div class="card biz-primary">
        <div class="card-header">
          {{$t('PR_INFO.COMPANY_INTRODUCTION')}}
        </div>
        <div class="card-body">
          <div class="biz-form-group">
            <div class="biz-form-body">
              <div class="row">
                <div class="col">
                  <FileUpload 
                    :image-path="form.companyInfo.fileManagementCode" 
                    :videoUrl="form.companyInfo.videoUrl"
                    @remove="form.companyInfo.fileManagementCode = null; form.companyInfo.videoUrl = null; form.companyInfo.dirty = true;"
                    @change="onFileUploadChange(form.companyInfo, $event)"/>
                </div>
                <div class="col al-child-mid">
                  <b-form-textarea 
                    rows="11" 
                    v-model="form.companyInfo.content" 
                    @change="form.companyInfo.dirty = true;"
                    :placeholder="$t('PR_INFO.COMPANY_INTRODUCTION_PLACEHOLDER')"></b-form-textarea>  
                  <div class="balloon-left">{{$t('COMMON.MAXIMUM_1000_WORDS')}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ -->
      <div class="card biz-primary mt-4">
        <div class="card-header">
          {{$t('PR_INFO.SERVICE_INTRODUCTION')}}
        </div>
        <div class="card-body">
          <div v-for="(service, index) in form.services.filter(s=>!s.deleteFlg)" v-bind:key="index">
            <div class="biz-form-group seperated">
              <div class="biz-form-body py-3">
                <div class="row">
                  <div class="col">
                    <FileUpload 
                      :image-path="service.fileManagementCode" 
                      :videoUrl="service.videoUrl"
                      @remove="service.fileManagementCode = null; service.videoUrl = null; service.dirty = true;"
                      @change="onFileUploadChange(service, $event)"/>
                  </div>
                  <div class="col al-child-mid">
                    <b-form-textarea rows="11" v-model="service.content" :placeholder="$t('PR_INFO.SERVICE_INTRODUCTION_PLACEHOLDER')" @change="service.dirty = true;"></b-form-textarea>
                    <div class="balloon-left">{{$t('COMMON.MAXIMUM_1000_WORDS')}}</div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="text-right mt-2">
                      <a href="javascript:void(0)" @click="onRemoveService(service, index)"><font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon>{{$t('PR_INFO.REMOVE_SERVICE_INTRODUCTION')}}</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="biz-form-group mt-4">
            <div class="">
              <a href="javascript:void(0)" @click="form.services.push({})"><font-awesome-icon :icon="['fas', 'plus']"></font-awesome-icon>{{$t('PR_INFO.ADD_SERVICE_INTRODUCTION')}}</a>
            </div>
          </div>
        </div>
      </div>
      
      <div class="card biz-primary">
        <div class="card-header">
          {{$t('PR_INFO.MESSAGE_FROM_SALES_MANAGER')}}
        </div>
        <div class="card-body">
          <div class="biz-form-group seperated pb-4">
            <div class="biz-form-body">
              <div class="row">
                <div class="col">
                  <FileUpload 
                    :only-image="true"
                    :image-path="form.message.fileManagementCode" 
                    :videoUrl="form.message.videoUrl"
                    @remove="form.message.fileManagementCode = null; form.message.videoUrl = null; form.message.dirty = true;"
                    @change="onFileUploadChange(form.message, $event)"/>
                </div>
                <div class="col al-child-mid">
                  <b-form-textarea rows="11" v-model="form.message.content" :placeholder="$t('PR_INFO.MESSAGE_FROM_SALES_MANAGER_PLACEHOLDER')" @change="form.message.dirty = true;"></b-form-textarea>
                  <div class="balloon-left">{{$t('COMMON.MAXIMUM_1000_WORDS')}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="biz-form-group mt-4">
            <label class="biz-form-label required">{{$t('PR_INFO.CONTRACT_DESTINATION')}}</label>
            <div class="biz-form-body">
              <div class="row">
                <div class="col-auto">
                  <p><small>{{$t('PR_INFO.AUTO_INPUT_FROM_USER_INFORMATION')}}</small></p>
                  <b-form-select :value="form.message.data.value" @change="onUserSelected">
                    <b-form-select-option v-for="(user, index) in users" v-bind:key="index" :value="user.value">{{user.text}}</b-form-select-option>
                  </b-form-select>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-3">
                  <b-form-group class="biz-input-group" :label="$t('COMMON.NAME')">
                    <b-form-input :placeholder="$t('COMMON.NAME_PLACEHOLDER')" v-model="form.message.data.name" @change="form.message.dirty = true;"/>
                  </b-form-group>
                </div>
                <div class="col-3">
                  <b-form-group class="biz-input-group" :label="$t('COMMON.PHONENUMBER')">
                    <b-form-input :placeholder="$t('COMMON.PHONENUMBER_PLACEHOLDER')" v-model="form.message.data.phoneNumber" @change="form.message.dirty = true;"/>
                  </b-form-group>
                </div>
                <div class="col-3">
                  <b-form-group class="biz-input-group" :label="$t('COMMON.EMAIL_ADDRESS')">
                    <b-form-input :placeholder="$t('COMMON.EMAIL_ADDRESS_PLACEHOLDER')" v-model="form.message.data.email" @change="form.message.dirty = true;"/>
                  </b-form-group>
                </div>
                <div class="col-3">
                  <b-form-group class="biz-input-group" :label="$t('COMMON.ADDRESS')">
                    <b-form-input v-model="form.message.data.address" @change="form.message.dirty = true;" :placeholder="$t('COMMON.ADDRESS_PLACEHOLDER')"/>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ -->
      <div class="card biz-primary">
        <div class="card-header">
          {{$t('PR_INFO.ACHIEVEMENT_AND_REWARD')}}
        </div>
        <div class="card-body">
          <b-form-textarea v-model="form.awards.content" class="w-100" style="min-height: 86px;"  @change="form.awards.dirty = true;"></b-form-textarea>
        </div>
      </div>
      <!-- \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ -->
      <div class="row mt-5">
        <div class="col-4">
          <a href="javascript:void(0)" @click="onPreview"><font-awesome-icon icon="external-link-alt"></font-awesome-icon>{{$t('COMMON.PREVIEW')}}</a>
        </div>
        <div class="col-4 text-center">
          <j-button variant="primary" size="lg" @click="onSave" >
            {{ !isRegistrationWizard ? $t('COMMON.SAVE'): $t('COMMON.NEXT') }}
          </j-button>
          <div class="mt-4" v-if="!isRegistrationWizard">
            <j-button variant="light" to="/supplier/manage/">
              <font-awesome-icon icon="arrow-left"></font-awesome-icon>
              {{$t('COMMON.GO_BACK')}}
            </j-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .balloon-left {
    position: relative;
    display: inline-block;
    margin: 8em 0 8em 15px;
    padding: 10px 10px;
    min-width: 120px;
    max-width: 100%;
    max-height: 50px;
    font-size: 13px;
    background: $biz-inactive;
  }
  .balloon-left:before {
    content: "";
    position: absolute;
    top: 50%;
    left: -30px;
    margin-top: -15px;
    border: 15px solid transparent;
    border-right: 25px solid $biz-inactive;
  }
  .balloon-left p {
    margin: 0;
    padding: 0;
  }
</style>

<script>
  import CompanyPRModel from '@/model/CompanyPR'
  import UserInfoModel from '@/model/UserInfo'

  import FileUpload from '@/components/common/FileUpload';

  export default {
    props: ["isRegistrationWizard"],
    components: { FileUpload },
    data: function(){
      return {
        form: {
          companyInfo: {},
          services: [],
          message: { data: {} },
          awards : {}
        },
        users: []
      }
    },
    created: function() {
      this.onLoad();
    },
    beforeDestroy: function(){
      this.$store.commit("setPrPreview", { form: {}, cb: () => {}});
    },
    methods:{
      onLoad(){
        Promise.all([
          CompanyPRModel.getPR(this.$store.state.user.companyCode),
          UserInfoModel.getUserSelect(this.$store.state.user.companyCode)
        ]).then(results => {
          let prData = results[0].data;
          prData.message.data = prData.message.data || { value: null };
          if(prData.services.length <= 0){ prData.services = [{}]; }
          
          if (Object.keys(this.$store.state.prPreview).length > 0) {
            this.form = this.$store.state.prPreview;
          }else this.form = prData;
          this.users = results[1].data;
          this.onUserSelected(results[1].data[0].value)
        }).catch(reason => this.$errorMessage(undefined, {reason}));
      },
      onSave(){
        CompanyPRModel.save(this.$store.state.user.companyCode, this.form).then(() => {
          this.$store.commit("setPrPreview", { form: {}, cb: () => {
            this.$successMessage(this.$t('PR_INFO.SUCCESS_MESSAGE'));  
            this.onLoad();
            this.$emit('onSave', 'CategoryInfo');
          }});
        }).catch(reason => this.$errorMessage(undefined, {reason}));
      },
      onFileUploadChange(data, event){
        if(event.type === 'IMAGE'){
          this.$set(data, 'fileManagementCode', event.path);
        }else{
          this.$set(data, 'videoUrl', event.path);
        }
        this.$set(data, 'dirty', true);
      },
      onRemoveService(service, index){
        if(service.code){
          service.deleteFlg = true; 
          service.dirty = true;
        }else{
          this.form.services.splice(index, 1);
        }
      },
      onPreview() {
        this.$store.commit("setPrPreview", { form: this.form, cb: () => {
          let route = this.$router.resolve({name: 'PublicRelationsPreview'});
          window.open(route.href, '_blank');
        }});
      },
      onUserSelected(value){
        for(let i=0; i < this.users.length; i++){
          if(this.users[i].value === value){
            this.form.message.data = {
              value: this.users[i].value,
              name: this.users[i].text,
              email: this.users[i].email,
              address: this.users[i].address,
              phoneNumber: this.users[i].phoneNumber,
            };
            break;
          }
        }
        this.form.message.dirty = true;
      }
    }
  }
</script>w