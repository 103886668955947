<template>
  <div class="file-upload">
    <div v-if="!imagePath">
        <b-button-group class="my-2" size="sm">
          <j-button class="no-min" :variant="type === 'IMAGE' ? 'primary' : 'light'" @click="type = 'IMAGE'">画像</j-button>
          <j-button v-if="!onlyImage" class="no-min" :variant="type === 'VIDEO' ? 'primary' : 'light'" @click="type = 'VIDEO'">動画</j-button>
        </b-button-group>
        <div v-if="type === 'IMAGE'" class="upload-area p-5" :class="dropHover?'highlighted':''" @dragover.prevent="!dropHover ? dropHover=true : null" @dragleave.prevent="dropHover=false" @drop.prevent="onFileUpload">
          <small>ファイルをドラッグ＆ドロップする<br/>または<br/></small>
          <input type="file" accept="image/jpeg, image/png" @change="onFileUpload">
          <br/><br/>
          <small>※画像ファイルは10MBまでアップロード可能です</small>
        </div>
        <div v-if="!onlyImage && type === 'VIDEO'" class="input-area p-5">
          <p class="label">動画URL</p>
          <b-input-group>
            <div class="input-group-prepend">
              <b-select class="w-10" @change="onVideoChange()" v-model="selectedHttps">
                <b-form-select-option v-for="(option, index) in httpsData" v-bind:key="index" :value="option">{{option}}</b-form-select-option>
              </b-select>
            </div>
            <b-form-input v-model="videoInput" @input="onVideoChange()" placeholder="www.company.co.jp/introduction.mp4"></b-form-input>
          </b-input-group>
          <p style="font-size: 12px;">https:// もしくは http:// を選択し、それ以降のURLをご入力ください。</p>
        </div>
    </div>
    <div v-else class="preview-area px-5 pt-5">
      <div>
        <img ref="preview_img" :src="imgObjectURL"/>
      </div>
       <p class="text-right"><a href="javascript:void(0);" @click="onRemoveAttachment"><font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon>{{type==='VIDEO'?'動画を削除':'画像を削除'}}</a></p>
    </div>
  </div>
  
</template>

<script>
import CompanyPRModel from '@/model/CompanyPR'
import FileUpload from '@/model/FileUpload'

export default {
  props: ["imagePath", "videoUrl", "onlyImage"],
  methods:{
    onFileUpload(e){
      this.dropHover = false;
      const images = e.target.files || e.dataTransfer.files;
      if (images.length === 0) {
        return;
      }
      for (let file of images) {
        if (file.size > 10485760) {
          this.$errorMessage("ファイルが大きすぎます。10MB以下の画像ファイルを選択してください。");
          return e.target.value = '';
        }
        if(file.name.toUpperCase().match(/\.(jpeg)$/i)||file.name.toUpperCase().match(/\.(jpg)$/i)||file.name.toUpperCase().match(/\.(png)$/i)){
          continue
        }else if(file.name.toUpperCase().match(/\.(jfif)$/i)||file.name.toUpperCase().match(/\.(pjpeg)$/i)||file.name.toUpperCase().match(/\.(pjp)$/i)){
          continue
        }else{
          this.$errorMessage("「JPG」または「PNG」の画像ファイルを選択してください。");
          return e.target.value = '';
        }
      }
      FileUpload.save(this.$store.state.user.companyCode, images[0], "image").then(result => {
        this.$emit('change', { type: 'IMAGE', path:result.data.path });
      }).catch(reason => this.$errorMessage("画像ファイルをアップロード出来ませんでした。", {reason}));
    },
    onVideoChange(){
      this.$emit('change', { type: 'VIDEO', path: this.selectedHttps + this.videoInput });
    },
    onRemoveAttachment(){
      this.$emit('remove');
    }
  },
  data: function(){
    return {
      type: this.videoUrl ? 'VIDEO':'IMAGE',
      videoInput: "",
      dropHover: false,
      imgObjectURL: "",
      selectedHttps: 'https://',
      httpsData: [
        'https://',
        'http://'
      ]
    }
  },
  mounted: function(){
    if(this.imagePath){
      CompanyPRModel.getImageFile(this.$store.state.user.companyCode, this.imagePath).then(file => {
        const blob = new Blob([file.data], { type: 'application/image' });
        this.imgObjectURL = URL.createObjectURL(blob);
      }).catch(reason => this.$errorMessage("画像ファイルを読み込みできませんでした。", {reason}));
    }
    if(this.videoUrl){
      let removeHttpUrl = this.videoUrl
      for(let data of this.httpsData){
          removeHttpUrl = removeHttpUrl.replaceAll(data,'')
      }
      this.videoInput = removeHttpUrl
    }
  },
  watch: {
      'imagePath':  {
          handler:function (){
              if(this.$refs.preview_img){
                URL.revokeObjectURL( this.$refs.preview_img.src);
              }
              if(this.imagePath){
                CompanyPRModel.getImageFile(this.$store.state.user.companyCode, this.imagePath).then(file => {
                  const blob = new Blob([file.data], { type: 'application/image' });
                  this.imgObjectURL = URL.createObjectURL(blob);
                }).catch(reason => this.$errorMessage("画像ファイルを読み込みできませんでした。", {reason}));
              }
          }
      },
      'videoUrl': {
        handler: function(){
          if(this.videoUrl) this.type='VIDEO';
        }
      }
  }
}
</script>

<style lang="scss" scoped>

  .file-upload{
    .upload-area{
      border: 2px dashed #c3c3c3;
      &.highlighted { border-color: $biz-primary; }
    }
    .input-area{
      border: 2px solid #c3c3c3;
    }

    .preview-area {
      border: 1px solid #c3c3c3;
      background: #f6f6f6;
      img, video{
        display:block;
        width: 40%;
        height: auto;
        margin: 0 auto;
      }
    }
  }

</style>